<template>
  <div class="login-form-container">
    <div class="login-form-wrapper">
      <div class="login-form__title"><h1>Login</h1></div>
      <form @submit.prevent="login" class="login-form">
        <div class="login-form__input-container">
          <input
            type="text"
            v-model="username"
            id="username"
            placeholder="Логин"
            required
          />
        </div>
        <div class="login-form__input-container">
          <input
            type="password"
            v-model="password"
            id="password"
            placeholder="Пароль"
            required
          />
        </div>
        <label for="rememberMe" class="login-form__label"
          >Запомнить меня:<input
            type="checkbox"
            v-model="rememberMe"
            id="rememberMe"
        /></label>
        <div v-if="error" class="error">{{ error }}</div>
        <button type="submit">Login</button>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "LoginPage",
  setup() {
    const username = ref("");
    const password = ref("");
    const rememberMe = ref(false);
    const error = ref("");
    const router = useRouter();

    const login = async () => {
      try {
        const response = await fetch("https://thiaminsfbf.site/api/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: username.value,
            password: password.value,
            rememberMe: rememberMe.value,
          }),
        });

        if (!response.ok) {
          const responseData = await response.json();
          throw new Error(responseData.message || "Failed to login");
        }

        const data = await response.json();
        const tokenExpiry = rememberMe.value
          ? 30 * 24 * 60 * 60 * 1000
          : 24 * 60 * 60 * 1000; // 30 дней или 24 часа
        const expiryDate = new Date().getTime() + tokenExpiry;
        localStorage.setItem("token", data.token);
        localStorage.setItem("tokenExpiry", expiryDate.toString());
        router.push("/home");
      } catch (err) {
        error.value = err.message;
      }
    };

    return { username, password, rememberMe, error, login };
  },
};
</script>

<style>
.error {
  color: red;
}
.login-form-container {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-form-wrapper {
  padding: 20px;
  border-radius: 10px;
  background: #5d44f8;
  color: #fff;
  width: clamp(300px, 80%, 320px);
}
.login-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}
.login-form__title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 30px;
  text-align: center;
}
.login-form__input-container {
  width: 100%;
}
.login-form__input-container input {
  width: 100%;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 1.1rem;
}
.login-form button {
  background-color: #fff;
  padding: 5px 15px;
  font-size: 1rem;
  border-radius: 5px;
  transition: 0.2s;
}
.login-form button:hover {
}
.login-form__label {
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 2px;
  border-radius: 5px;
}
.login-form__label:hover,
.login-form__label input {
  cursor: pointer;
}
.login-form__label:focus {
  box-shadow: 0 0 5px 0 #fff;
}
.login-form__label input {
  accent-color: lightgreen;
}
</style>
