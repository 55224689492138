import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/HomePage.vue';
import Whois from '../views/WhoisPage.vue';
import SSLChecker from '../views/SSLPage.vue';
import URLResolver from '../views/ResolverPage.vue';
import LoginPage from '../views/LoginPage.vue';
import SettingsPage from '../views/SettingsPage.vue';
import PowarkaPage from '../views/PowarkaPage.vue';
import DNSPage from '../views/DNSPage.vue';
import ServersPage from '../views/ServersPage.vue';
import { jwtDecode } from "jwt-decode";

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', component: LoginPage },
  { path: '/home', component: Home },
  { path: '/whois', component: Whois },
  { path: '/ssl-checker', component: SSLChecker },
  { path: '/settings', component: SettingsPage, meta: { requiresAdmin: true } },
  { path: '/page-resolver', component: URLResolver },
  { path: '/powarka', component: PowarkaPage },
  { path: '/dig-dns', component: DNSPage },
  { path: '/servers-configuration', component: ServersPage }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  console.log("Component InitialTabs created");
  const token = localStorage.getItem("token");
  let user = null;
  if (token) {
    try {
      user = jwtDecode(token);
    } catch (error) {
      console.error("Failed to decode token:", error);
    }
  }
  if (to.matched.some(record => record.meta.requiresAdmin) && (!user || user?.role !== 'admin')) {
    next('/home');
  } else {
    next();
  }
});

export default router;
