<template>
  <div class="wrapper">
    <div class="widget-container">
      <h2>Post Form (advanced):</h2>
      <div class="input-container">
        <label>
          <span class="save-message"></span>
          <input
            type="text"
            placeholder="Post curl bash (advanced save)"
            v-model="advancedSaveInput"
            autocomplete="off"
            @input="parseAdvancedSaveBash"
          />
        </label>
        <button type="button" class="copy-btn" @click="copyInputValue(advancedSaveInput)">Copy</button>
      </div>
    </div>
    <div class="widget-line"></div>
    <div class="widget-container">
      <h2>Post Form For App (basic):</h2>
      <div class="input-container">
        <label>
          <span class="save-message"></span>
          <input
            type="text"
            placeholder="Post curl bash (basic save)"
            v-model="basicSaveInput"
            autocomplete="off"
            @input="parseBasicSaveBash"
          />
        </label>
        <button type="button" class="copy-btn" @click="copyInputValue(basicSaveInput)">Copy</button>
      </div>
    </div>
    <div class="widget-line"></div>
    <div class="widget-container">
      <h2>UAC IDs</h2>
      <div class="input-container input-container--uac">
        <label>
          <span class="uac-id-message" v-if="!isLinkIdValid">Invalid link_id</span>
          <input
            class="uac-id-input"
            type="text"
            v-model="linkId"
            placeholder="Вставьте link_id"
            @input="checkLinkId"
          />
        </label>
        <label>
          <span class="uac-id-message" v-if="!isRdidValid">Invalid rdid</span>
          <input
            class="uac-id-input"
            type="text"
            v-model="rdid"
            placeholder="Вставьте rdid"
            @input="checkRdid"
          />
        </label>
        <button type="button" :disabled="!canGenerateUacIds" @click="generateUacIds">Generate!</button>
      </div>
      <div class="result-container">
        <div
          class="result-uac-id-input-wrapper input-container"
          v-for="(result, index) in uacResults"
          :key="index"
        >
          <label>
            <input type="text" v-model="uacResults[index]" />
          </label>
          <button type="button" class="copy-btn" @click="copyInputValue(uacResults[index])">Copy</button>
          <a :href="result" class="open-link-btn" target="_blank">Open</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';

export default {
  name: 'PowarkaPage',
  setup() {
    const advancedSaveInput = ref('');
    const basicSaveInput = ref('');
    const linkId = ref('');
    const rdid = ref('');
    const uacResults = ref(new Array(4).fill(''));
    const URLs = [
      "https://www.googleadservices.com/pagead/conversion/app/1.0?dev_token=cBjRQe1AwH8nBOqNQf1IVA&link_id=aaaaasssss&app_event_type=custom&rdid=gsdfasd&id_type=advertisingid&lat=0&app_version=1.0&os_version=12&sdk_version=12&timestamp=1696321533&app_event_name=sign_up",
      "https://www.googleadservices.com/pagead/conversion/app/1.0?dev_token=cBjRQe1AwH8nBOqNQf1IVA&link_id=aaaaasssss&app_event_type=first_open&rdid=gsdfasd&id_type=advertisingid&lat=0&app_version=1.0&os_version=12&sdk_version=12&timestamp=1696321533",
      "https://www.googleadservices.com/pagead/conversion/app/1.0?dev_token=cBjRQe1AwH8nBOqNQf1IVA&link_id=aaaaasssss&app_event_type=session_start&rdid=gsdfasd&id_type=advertisingid&lat=0&app_version=1.0&os_version=12&sdk_version=12&timestamp=1696321533",
      "https://www.googleadservices.com/pagead/conversion/app/1.0?dev_token=cBjRQe1AwH8nBOqNQf1IVA&link_id=aaaaasssss&app_event_type=in_app_purchase&rdid=gsdfasd&id_type=advertisingid&lat=0&app_version=1.0&os_version=12&sdk_version=12&timestamp=1696321533"
    ];

    const parseSaveString = async (input, testRegex, replaceRegex, replaceWithString) => {
      let saveMessage = ref('');
      if (testRegex.test(input.value)) {
        let bashString = input.value.match(/(?<=--data-raw\s').+[^']/)[0];
        bashString = bashString.replace(replaceRegex, replaceWithString);
        input.value = bashString;
        saveMessage.value = "Parse and Copied!";
        await copyToClipboard(input.value);
      } else {
        saveMessage.value = "INVALID CURL!";
        input.value = "";
      }
    };

    const parseAdvancedSaveBash = () => {
      parseSaveString(
        advancedSaveInput,
        /https:\/\/developers\.facebook\.com\/x\/apps\/\d+\/settings\/advanced\/save\/.+jazoest.+advertiser_account_ids.+/,
        /advertiser_account_ids.+(?=&allow_share_redirects)/,
        "{{dataRaw}}"
      );
    };

    const parseBasicSaveBash = () => {
      parseSaveString(
        basicSaveInput,
        /https:\/\/developers\.facebook\.com\/x\/apps\/\d+\/settings\/basic\/save\/.+jazoest.+android_package_names.+/,
        /&platforms.+=google_play/,
        ""
      );
    };

    const isLinkIdValid = computed(() => /[\w|\d]{32}/.test(linkId.value));
    const isRdidValid = computed(() => /[\w|\d]{8}-[\w|\d]{4}-[\w|\d]{4}-[\w|\d]{4}-[\w|\d]{12}/.test(rdid.value));
    const canGenerateUacIds = computed(() => isLinkIdValid.value && isRdidValid.value);

    const checkLinkId = () => {
      if (!isLinkIdValid.value) linkId.value = '';
    };

    const checkRdid = () => {
      if (!isRdidValid.value) rdid.value = '';
    };

    const generateUacIds = () => {
      for (let i = 0; i < URLs.length; i++) {
        let url = new URL(URLs[i]);
        url.searchParams.set("link_id", linkId.value);
        url.searchParams.set("rdid", rdid.value);
        url.searchParams.set("timestamp", Math.floor(Date.now() / 1000));
        uacResults.value[i] = url.toString();
      }
    };

    const copyToClipboard = async (textToCopy) => {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(textToCopy);
      } else {
        const textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        textArea.style.position = "absolute";
        textArea.style.left = "-999999px";
        document.body.prepend(textArea);
        textArea.select();
        try {
          document.execCommand("copy");
        } catch (error) {
          console.error(error);
        } finally {
          textArea.remove();
        }
      }
    };

    const copyInputValue = async (inputRef) => {
      try {
        await copyToClipboard(inputRef);
        const copiedMessage = document.createElement("span");
        copiedMessage.classList.add("copied-message");
        copiedMessage.textContent = "Copied!";
        document.body.appendChild(copiedMessage);
        setTimeout(() => copiedMessage.remove(), 150);
      } catch (error) {
        console.error(error);
      }
    };

    return {
      advancedSaveInput,
      basicSaveInput,
      linkId,
      rdid,
      uacResults,
      isLinkIdValid,
      isRdidValid,
      canGenerateUacIds,
      parseAdvancedSaveBash,
      parseBasicSaveBash,
      checkLinkId,
      checkRdid,
      generateUacIds,
      copyInputValue
    };
  }
};
</script>

<style scoped>
    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }

    html {
        height: 100%;
    }

    body {
        min-height: 100%;
    }

    a {
        text-decoration: none;
    }

    label {
        position: relative;
        width: clamp(300px, 80%, 340px);
    }

    label span {
        position: absolute;
        top: 100%;
        transform: translateY(4px);
        font-size: 14px;
        display: none;
    }

    label span.success {
        display: inline;
        color: #0eb90e;
        font-weight: 600;
    }

    label span.invalid {
        display: inline;
        color: red;
    }

    input {
        padding: 4px 5px;
        outline: none;
        border: 1px solid #ccc;
        width: 100%;
        height: 100%;
    }

    input.copied {
        background-color: #8782824f;
    }

    h2 {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 5px;
    }

    .wrapper {
        padding: 10px 0 0 10px;
        width: clamp(300px, 80%, 640px);
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .widget-line {
        height: 2px;
        width: 100%;
        background-color: #ccc;
    }

    .input-container {
        display: flex;
    }

    .input-container--uac {
        margin-bottom: 40px;
    }

    .enter-wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
    }

    .enter-inputs-container {
        display: flex;
    }

    #generateUacIDsBtn:disabled {
        color: #b6b6b6;
    }

    #generateUacIDsBtn:disabled:hover {
        cursor: not-allowed;
        opacity: 1;
    }

    input.valid {
        border: 1px solid #0eb90e;
    }

    input.invalid {
        border: 1px solid red;
    }

    .result-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .result-uac-id-input-wrapper {
        display: flex;
    }

    button,
    a {
        padding: 4px 10px;
        font-weight: 500;
        font-size: 15px;
        display: block;
        transition: 0.1s;
        color: black;
    }

    button svg {
        width: 15px;
        height: 15px;
    }

    a:visited,
    a:active {
        color: black;
    }

    a {
        background-color: rgb(240, 240, 240);
        border: 1px solid rgb(0, 0, 0);
    }

    button:hover,
    a:hover {
        cursor: pointer;
        opacity: 0.8;
    }

    .copied-message {
        position: absolute;
        z-index: 9;
        padding: 2px 5px;
        background-color: rgb(20, 20, 20);
        color: white;
    }
</style>