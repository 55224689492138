<template>
  <div class="dns">
    <h1>DNS Information</h1>
    <form @submit.prevent="fetchDNSRecords">
      <div class="dns-input-wrapper">
        <input
          v-model="domain"
          id="domain"
          placeholder="Enter domain name"
          type="text"
          required
          @keydown.enter="fetchDNSRecords"
        />
        <button type="submit">Check DNS</button>
      </div>
      <div class="record-type-select">
        <div
          class="record-type-option"
          v-for="type in recordTypes"
          :key="type"
          :class="{ active: type === recordType }"
          @click="changeRecordType(type)"
        >
          {{ type }}
        </div>
      </div>
    </form>
    <div v-if="dnsRecords !== null" class="result-wrapper">
      <h2>{{ recordType }} Records for {{ searchedDomain }}</h2>
      <template v-if="dnsRecords.error">
        <p class="error">{{ dnsRecords.error }}</p>
      </template>
      <template v-else>
        <ul v-if="dnsRecords.records.length > 0">
          <li v-for="(record, index) in dnsRecords.records" :key="index">
            <span v-if="recordType === 'MX'">
              {{ record.exchange }} | Priority {{ record.priority }}
            </span>
            <span v-else-if="Array.isArray(record)">
              {{ record[0] }}
            </span>
            <span v-else>
              {{ record }}
            </span>
          </li>
        </ul>
        <p v-else>No records of type {{ recordType }} found.</p>
      </template>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      domain: "",
      recordType: "A",
      recordTypes: ["A", "AAAA", "CNAME", "TXT", "MX", "NS"],
      dnsRecords: null,
    };
  },
  methods: {
    async fetchDNSRecords() {
      this.dnsRecords = null;
      this.searchedDomain = this.domain;
      try {
        const response = await axios.get(
          `https://thiaminsfbf.site/api/dns/${this.domain}/${this.recordType}`
        );
        this.dnsRecords = response.data;
      } catch (error) {
        console.error("Error fetching DNS records:", error);
        this.dnsRecords = {
          error:
            "No records of such type or there is a temporary issue. Please try again later.",
        };
      } 
    },
    changeRecordType(type) {
      this.recordType = type;
      if (this.domain.trim() !== "") {
        this.fetchDNSRecords();
      }
    },
  },
};
</script>

<style scoped>
.dns {
  margin: auto;
  text-align: center;
  width: clamp(300px, 90%, 800px);
  background-color: #f5f1f1ba;
  border-radius: 10px;
  padding: 40px 20px;
  min-height: 600px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
form {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 15px;
}
.dns-input-wrapper {
  width: 100%;
  display: flex;
}
input {
  flex-grow: 1;
  padding: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  transition: 0.2s;
}
input:focus {
  box-shadow: inset 0 0 0 2px #a39fff;
}
button {
  padding: 8px 12px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #a39fff;
  color: #fff;
  transition: 0.2s;
}
button:focus,
button:hover {
  box-shadow: inset 0 0 0 2px #a39fff;
  color: #a39fff;
  background-color: #fff;
}
h1 {
  position: relative;
}
h1::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #212c4b;
}
h2 {
  font-weight: 500;
  font-size: 1.1rem;
  margin-bottom: 15px;
}
.result-wrapper {
  padding-top: 20px;
  text-align: left;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 600px;
  overflow: auto;
}
.error {
  color: red;
}
.record-type-select {
  display: flex;
  justify-content: center;
}
.record-type-option {
  padding: 8px 12px;
  background-color: #a39fff;
  color: #fff;
  transition: 0.2s;
  cursor: pointer;
}
.record-type-option:hover,
.record-type-option.active {
  box-shadow: inset 0 0 0 2px #a39fff;
  color: #a39fff;
  background-color: #fff;
}
li {
  position: relative;
  padding-left: 15px;
  word-break: break-all;
}
li::before {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: black;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
li:not(:last-child) {
  margin-bottom: 10px;
}
</style>
