<template>
  <div class="nav-links">
    <router-link to="/home">Home</router-link>
    <router-link v-if="['admin', 'first_line'].includes(userRole)" to="/whois"
      >Whois</router-link
    >
    <router-link
      v-if="['admin', 'first_line'].includes(userRole)"
      to="/ssl-checker"
      >SSL Checker</router-link
    >
    <!-- <router-link
      v-if="['admin', 'first_line'].includes(userRole)"
      to="/page-resolver"
      >URL Resolver</router-link
    > -->
    <router-link v-if="['admin', 'first_line'].includes(userRole)" to="/dig-dns"
      >Dig DNS</router-link
    >
    <router-link
      v-if="['admin', 'first_line'].includes(userRole)"
      to="/servers-configuration"
      >Configure Servers</router-link
    >
    <router-link v-if="['admin', 'powarka'].includes(userRole)" to="/powarka"
      >Powarka</router-link
    >
    <router-link v-if="userRole === 'admin'" to="/settings"
      >Settings</router-link
    >
    <button @click="logout">Logout</button>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { jwtDecode } from "jwt-decode";

export default {
  name: "InitialTabs",
  data() {
    return {
      userRole: "",
    };
  },
  created() {
    console.log("Component InitialTabs created");
    const token = localStorage.getItem("token");
    if (token) {
      console.log("key");
      try {
        const decoded = jwtDecode(token);
        console.log("Decoded token:", decoded); // Проверьте, что токен успешно раскодирован
        this.userRole = decoded.role;
      } catch (error) {
        console.error("Failed to decode token:", error);
      }
    }
  },
  setup() {
    const router = useRouter();

    const logout = () => {
      localStorage.removeItem("token");
      router.push("/login");
    };

    return { logout };
  },
};
</script>

<style>
.nav-links {
  display: flex;
  justify-content: end;
}
.nav-links a,
.nav-links button {
  padding: 5px 15px;
  background-color: #5d44f8;
  color: #fff !important;
  box-shadow: 0 0 0 2px #fff;
  position: relative;
}
.nav-links a::after,
.nav-links button::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #5d44f8;
  transition: 0.2s;
}
.nav-links a.router-link-exact-active,
.nav-links button:hover {
  background-color: #fff;
  color: #5d44f8 !important;
}
.nav-links a.router-link-exact-active::after,
.nav-links button:hover::after {
  width: 100%;
}
</style>
