<template>
  <div id="app">
    <InitialTabs v-if="isLoggedIn" />
    <router-view />
  </div>
</template>

<script>
import InitialTabs from './views/InitialTabs.vue';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'App',
  components: {
    InitialTabs
  },
  setup() {
    const isLoggedIn = ref(!!localStorage.getItem('token'));
    const router = useRouter();

    const updateLoginStatus = () => {
      isLoggedIn.value = !!localStorage.getItem('token');
    };

    router.afterEach(() => {
      updateLoginStatus();
    });

    return { isLoggedIn };
  }
};
</script>

<style>
/* Здесь можно добавить стили для компонента App.vue */
</style>
