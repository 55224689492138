<template>
  <div class="servers-conf">
    <h1>Servers Configuration</h1>
    <input
      type="text"
      placeholder="Server details"
      v-model="serverDetails"
      @input="extractServerDetails"
      class="detailsFromFH"
    />
    <div class="selection-wrapper">
      <h2>Select Action</h2>
      <div class="labels-container">
        <label>
          <input
            type="radio"
            name="action"
            value="changePort"
            v-model="selectedAction"
          />
          Change Port
        </label>
        <label>
          <input
            type="radio"
            name="action"
            value="setUpPF"
            v-model="selectedAction"
          />
          Set up PF
        </label>
        <label>
          <input
            type="radio"
            name="action"
            value="setUpServer"
            v-model="selectedAction"
          />
          Set up server
        </label>
        <label>
          <input
            type="radio"
            name="action"
            value="secureWordpress"
            v-model="selectedAction"
          />
          Secure WordPress
        </label>
      </div>
    </div>

    <form>
      <h2>Server Details</h2>
      <div class="server-inputs">
        <input
          type="text"
          id="serverIp"
          v-model="serverIp"
          placeholder="Server IP"
        />
        <input
          type="text"
          id="serverPassword"
          v-model="serverPassword"
          placeholder="Server Password"
        />
        <input
          type="text"
          id="currentPort"
          v-model="currentPort"
          placeholder="Current Port"
        />

        <!-- Условное отображение дополнительных input полей -->
        <input
          v-if="selectedAction === 'changePort'"
          type="text"
          id="newPort"
          v-model="newPort"
          placeholder="New Port"
        />
        <input
          v-if="selectedAction === 'setUpPF'"
          type="text"
          id="pfCurlString"
          v-model="pfCurlString"
          placeholder="PF Curl String"
        />
        <input
          v-if="selectedAction === 'setUpServer'"
          type="text"
          id="domain"
          v-model="domain"
          placeholder="Domain"
        />

        <button @click="handleSubmit">Submit</button>
      </div>
    </form>
    <div v-if="loading">
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div v-if="message">{{ message }}</div>
    <div v-if="error" class="red">{{ error }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      serverIp: "",
      serverPassword: "",
      currentPort: "3333",
      serverDetails: "",
      newPort: "22",
      pfCurlString: "",
      domain: "",
      selectedAction: "",
      message: "",
      error: "",
      loading: false,
    };
  },
  methods: {
    extractServerDetails() {
      // Regex to extract IP, Password, and Port from the input string
      const ipRegex = /(?:[0-9]{1,3}\.){3}[0-9]{1,3}/;
      const passwordRegex = /Пароль\s*---\s\|(\S+)/;
      const portRegex = /Порт\s*---\s*(\d+)/;

      const ipMatch = this.serverDetails.match(ipRegex);
      const passwordMatch = this.serverDetails.match(passwordRegex);
      const portMatch = this.serverDetails.match(portRegex);
      console.log("ipMatch", ipMatch);
      // Assign values to corresponding fields if matches are found
      if (ipMatch) this.serverIp = ipMatch[0];
      if (passwordMatch) this.serverPassword = passwordMatch[1];
      if (portMatch) this.currentPort = portMatch[1];
    },
    handleSubmit(event) {
      event.preventDefault();
      this.loading = true; // показываем лоадер
      this.message = ""; // очищаем сообщение
      this.error = ""; // очищаем ошибку
      console.log("selectedAction", this.selectedAction);
      let url = "https://localhost:3000";
      let body = {
        serverIp: this.serverIp,
        serverPassword: this.serverPassword,
        currentPort: this.currentPort,
        serverLogin: "root",
      };

      switch (this.selectedAction) {
        case "changePort":
          url += "/api/change-port";
          body.newPort = this.newPort;
          break;

        case "Set up PF":
          url += "/api/set-up-pf";
          body.pfCurlString = this.pfCurlString;
          break;

        case "Set up server":
          url += "/api/configure-server";
          body.domain = this.domain;
          break;

        case "Secure WordPress":
          url += "/api/secure-wordpress";
          break;

        default:
          this.error = "Please select an action";
          return;
      }

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((data) => {
          this.loading = false;
          if (data.error) {
            this.error = data.error;
          } else {
            this.message = data.message;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.error = "Failed to send request: " + err.message;
        });
    },
  },
};
</script>

<style scoped>
.servers-conf {
  margin: auto;
  text-align: center;
  width: clamp(300px, 90%, 800px);
  background-color: #f5f1f1ba;
  border-radius: 10px;
  padding: 40px 20px;
  height: 600px;
  display: flex;
  flex-direction: column;
}
.selection-wrapper {
  margin-bottom: 40px;
}
form {
  margin-bottom: 20px;
}
.server-inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.server-inputs input {
  padding: 10px;
  border-radius: 10px;
  transition: 0.2s;
}
.server-inputs input:focus {
  box-shadow: inset 0 0 0 2px #a39fff;
}
.labels-container {
  display: flex;
  justify-content: center;
  gap: 15px;
}
label {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 5px;
  cursor: pointer;
}
button {
  padding: 8px 12px;
  border-radius: 10px;
  background-color: #a39fff;
  color: #fff;
  transition: 0.2s;
}
button:focus,
button:hover {
  box-shadow: inset 0 0 0 2px #a39fff;
  color: #a39fff;
  background-color: #fff;
}
h1 {
  position: relative;
}
h1::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #212c4b;
}
h2 {
  font-weight: 500;
  font-size: 1.3rem;
  margin-bottom: 15px;
}
.result-wrapper {
  text-align: left;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.detailsFromFH {
  padding: 5px 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.error {
  color: red;
}

.lds-ellipsis,
.lds-ellipsis div {
  box-sizing: border-box;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33.33333px;
  width: 13.33333px;
  height: 13.33333px;
  border-radius: 50%;
  background: currentColor;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}


</style>
