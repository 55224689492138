<template>
  <div class="settings-wrapper">
    <h1>Settings</h1>
    <!-- Форма для добавления/редактирования пользователей -->
    <form @submit.prevent="handleSubmit">
      <label>Username:</label>
      <input type="text" v-model="formData.username" required />
      <label>Password:</label>
      <input type="password" v-model="formData.password" required />
      <label>Role:</label>
      <select v-model="formData.role">
        <option value="first_line">First Line</option>
        <option value="powarka">Powarka</option>
        <option value="admin">Admin</option>
      </select>
      <button type="submit">Save</button>
    </form>

    <!-- Таблица пользователей -->
    <h2>Existing Users</h2>
    <table>
      <thead>
        <tr>
          <th>Username</th>
          <th>Role</th>
          <th>Password</th>
          <th>Actions</th>
          <!-- Новый столбец для действий -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user._id">
          <td>{{ user.username }}</td>
          <td>{{ user.role }}</td>
          <td>
            <div v-if="!editingPassword[user._id]">
              <span>********</span>
              <button @click="startEditing(user._id)">Change Password</button>
            </div>
            <div v-else>
              <input
                type="password"
                v-model="newPassword"
                @keyup.enter="updatePassword(user._id)"
                placeholder="Enter new password"
              />
              <button @click="updatePassword(user._id)">Change Password</button>
            </div>
          </td>
          <td>
            <button @click="deleteUser(user._id)">🗑️</button>
            <!-- Иконка удаления -->
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="successMessage">{{ successMessage }}</div>
    <div v-if="errorMessage">{{ errorMessage }}</div>
  </div>
</template>

<script>
export default {
  name: "SettingsPage",
  data() {
    return {
      formData: {
        username: "",
        password: "",
        role: "first_line",
      },
      users: [],
      editingPassword: {},
      newPassword: "",
      successMessage: "",
      errorMessage: "",
    };
  },
  methods: {
    async handleSubmit() {
      let url = "https://localhost:3000";
      try {
        let response;
        if (this.formData._id) {
          response = await fetch(`${url}/api/users/${this.formData._id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(this.formData),
          });
        } else {
          response = await fetch(`${url}/api/users/register`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(this.formData),
          });
        }
        if (!response.ok) {
          throw new Error("Error saving user");
        }
        alert("User saved successfully");
        this.formData = { username: "", password: "", role: "first_line" };
        this.fetchUsers(); // Обновить список пользователей после сохранения
      } catch (error) {
        console.error(error);
        alert("Failed to save user");
      }
    },
    async fetchUsers() {
      let url = "https://localhost:3000";
      const response = await fetch(`${url}/api/users`);
      const data = await response.json();
      this.users = data;
    },
    startEditing(userId) {
      this.editingPassword = { [userId]: true };
    },
    async updatePassword(userId) {
      const response = await fetch(
        `https://thiaminsfbf.site/api/users/${userId}/update-password`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ password: this.newPassword }),
        }
      );
      if (response.ok) {
        this.successMessage = "Password updated successfully";
        this.errorMessage = "";
        this.newPassword = "";
        this.editingPassword = { [userId]: false };
        this.fetchUsers(); // Обновить список пользователей после изменения пароля
      } else {
        this.successMessage = "";
        this.errorMessage = "Failed to update password";
      }
    },
    async deleteUser(userId) {
      if (confirm("Are you sure you want to delete this user?")) {
        try {
          const response = await fetch(
            `https://thiaminsfbf.site/api/users/${userId}`,
            {
              method: "DELETE",
            }
          );
          if (response.ok) {
            this.successMessage = "User deleted successfully";
            this.errorMessage = "";
            this.fetchUsers(); // Обновить список пользователей после удаления
          } else {
            const errorData = await response.json();
            this.successMessage = "";
            this.errorMessage = `Failed to delete user: ${errorData.error}`;
          }
        } catch (error) {
          console.error("Error deleting user:", error);
          this.successMessage = "";
          this.errorMessage = "Failed to delete user";
        }
      }
    },
  },
  created() {
    this.fetchUsers();
  },
};
</script>

<style scoped>
/* Пример стилей */
.settings-wrapper {
  padding: 15px;
}
h2 {
  margin-bottom: 10px;
}
form {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-top: 10px;
}

input[type="text"],
input[type="password"],
select {
  width: 100%;
  padding: 8px;
  margin: 5px 0 10px;
}

button {
  padding: 8px 12px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  background-color: #a39fff;
  color: #fff;
  transition: 0.2s;
}

button:hover {
  box-shadow: inset 0 0 0 2px #a39fff;
  color: #a39fff;
  background-color: #fff;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table,
th,
td {
  border: 1px solid #ddd;
}

th,
td {
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}
</style>
