<template>
  <div class="whois">
    <h1>Whois Information</h1>
    <form @submit.prevent="fetchWhois">
      <input
        type="text"
        id="domain"
        v-model="domain"
        placeholder="Enter domain name"
        required
      />
      <button type="submit">Check Whois</button>
    </form>
    <div v-if="loading" class="loader-wrapper">
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div v-if="whoisData" class="result-wrapper">
      <h2>Whois Details for {{ whoisData["Domain Name"] }}</h2>
      <p>
        <strong>Domain:</strong> {{ whoisData["Domain Name"].toLowerCase() }}
      </p>
      <p><strong>Registrar:</strong> {{ whoisData["Registrar"] }}</p>
      <p><strong>Registry:</strong> {{ registryName }}</p>
      <p><strong>Creation Date:</strong> {{ whoisData["Created Date"] }}</p>
      <p><strong>Expiration Date:</strong> {{ whoisData["Expiry Date"] }}</p>
      <p><strong>Updated Date:</strong> {{ whoisData["Updated Date"] }}</p>
      <p><strong>Nameservers:</strong></p>
      <ul>
        <li v-for="ns in whoisData['Name Server']" :key="ns">{{ ns }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      domain: "",
      whoisData: null,
      registryName: "",
      loading: false,
    };
  },
  methods: {
    async fetchWhois() {
      this.loading = true;
      this.whoisData = null;
      try {
        const response = await axios.get(
          `https://thiaminsfbf.site/api/whois/${this.domain}`
        );
        const firstRegistry = Object.keys(response.data)[0];
        this.whoisData = response.data[firstRegistry];
        this.registryName = firstRegistry;
        this.whoisData["Domain Name"] =
          this.whoisData["Domain Name"].toUpperCase();
      } catch (error) {
        console.error("Error fetching whois data:", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.whois {
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
  width: clamp(300px, 90%, 800px);
  background-color: #f5f1f1ba;
  border-radius: 10px;
  padding: 40px 20px;
  height: 600px;
}
form {
  display: flex;
  margin-bottom: 20px;
}
input {
  padding: 10px;
  flex-grow: 1;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  transition: 0.2s;
}
input:focus {
  box-shadow: inset 0 0 0 2px #a39fff;
}
button {
  padding: 8px 12px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #a39fff;
  color: #fff;
  transition: 0.2s;
}
button:focus,
button:hover {
  box-shadow: inset 0 0 0 2px #a39fff;
  color: #a39fff;
  background-color: #fff;
}
h1 {
  position: relative;
}
h1::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #212c4b;
}
h2 {
  font-weight: 500;
  font-size: 1.3rem;
  margin-bottom: 15px;
}
.result-wrapper {
  text-align: left;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}
.lds-ellipsis,
.lds-ellipsis div {
  box-sizing: border-box;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33.33333px;
  width: 13.33333px;
  height: 13.33333px;
  border-radius: 50%;
  background: currentColor;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>
