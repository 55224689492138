<template>
  <div>
    <h2>URL Resolver</h2>
    <form @submit.prevent="resolveUrl">
      <label for="url">Enter URL:</label>
      <input type="text" id="url" v-model="url" required>
      <button type="submit">Resolve URL</button>
    </form>
    <div v-if="resolvedUrls.length > 0">
      <h3>Resolved URLs and Status Codes:</h3>
      <ul>
        <li v-for="(item, index) in resolvedUrls" :key="index">
          <p><strong>URL:</strong> {{ item.url }}</p>
          <p><strong>Status Code:</strong> {{ item.statusCode }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      url: '',
      resolvedUrls: []
    };
  },
  methods: {
    async resolveUrl() {
      try {
        const response = await axios.post('https://thiaminsfbf.site/resolve-url', {
          url: this.url
        });
        this.resolvedUrls = response.data;
      } catch (error) {
        console.error('Error resolving URL:', error.message);
      }
    }
  }
};
</script>
