<template>
  <div class="ssl">
    <h1>SSL Certificate Checker</h1>
    <form @submit.prevent="checkSSL">
      <input
        type="text"
        id="domain"
        v-model="domain"
        required
        placeholder="Enter domain"
      />
      <button type="submit">Check SSL</button>
    </form>
    <div v-if="loading" class="loader-wrapper">
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div v-if="sslInfo">
      <h3 v-if="sslInfo.error" class="error">{{ sslInfo.error }}</h3>
      <div v-else>
        <div v-if="sslInfo.result" class="result-wrapper">
          <h2>SSL Details for {{ sslInfo.result.host }}</h2>
          <p><strong>Host:</strong> {{ sslInfo.result.host }}</p>
          <p><strong>Resolved IP:</strong> {{ sslInfo.result.resolved_ip }}</p>
          <p><strong>Issued To:</strong> {{ sslInfo.result.issued_to }}</p>
          <p>
            <strong>Issuer Organization:</strong> {{ sslInfo.result.issuer_o }}
          </p>
          <p><strong>Issuer Country:</strong> {{ sslInfo.result.issuer_c }}</p>
          <p>
            <strong>Issuer Common Name:</strong> {{ sslInfo.result.issuer_cn }}
          </p>
          <p><strong>Serial Number:</strong> {{ sslInfo.result.cert_sn }}</p>
          <p>
            <strong>SHA1 Thumbprint:</strong> {{ sslInfo.result.cert_sha1 }}
          </p>
          <p><strong>Algorithm:</strong> {{ sslInfo.result.cert_alg }}</p>
          <p>
            <strong>Validity:</strong> {{ sslInfo.result.valid_from }} -
            {{ sslInfo.result.valid_till }}
          </p>
          <p>
            <strong>Validity Days:</strong> {{ sslInfo.result.validity_days }}
          </p>
          <p><strong>Days Left:</strong> {{ sslInfo.result.days_left }}</p>
          <p>
            <strong>HSTS Header Enabled:</strong>
            {{ sslInfo.result.hsts_header_enabled }}
          </p>
        </div>
        <p v-else>Domain is available for registration</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      domain: "",
      sslInfo: null,
      loading: false,
    };
  },
  methods: {
    async checkSSL() {
      this.loading = true;
      this.sslInfo = null;
      try {
        const response = await fetch("https://thiaminsfbf.site/check-ssl", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ domain: this.domain }),
        });
        if (!response.ok) {
          throw new Error("Failed to fetch SSL info");
        }
        const data = await response.json();
        this.sslInfo = data;
        if (data.status === "error") {
          this.sslInfo = { error: "No SSL installed" };
        }
      } catch (error) {
        console.error("Error:", error.message);
        this.sslInfo = { error: "Failed to fetch SSL info" };
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.ssl {
  margin: auto;
  text-align: center;
  width: clamp(300px, 90%, 800px);
  background-color: #f5f1f1ba;
  border-radius: 10px;
  padding: 40px 20px;
  height: 600px;
  display: flex;
  flex-direction: column;
}
form {
  display: flex;
  margin-bottom: 20px;
}
input {
  padding: 10px;
  flex-grow: 1;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  transition: 0.2s;
}
input:focus {
  box-shadow: inset 0 0 0 2px #a39fff;
}
button {
  padding: 8px 12px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #a39fff;
  color: #fff;
  transition: 0.2s;
}
button:focus,
button:hover {
  box-shadow: inset 0 0 0 2px #a39fff;
  color: #a39fff;
  background-color: #fff;
}
h1 {
  position: relative;
}
h1::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #212c4b;
}
h2 {
  font-weight: 500;
  font-size: 1.3rem;
  margin-bottom: 15px;
}
.result-wrapper {
  text-align: left;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.error {
  color: red;
}
.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}
.lds-ellipsis,
.lds-ellipsis div {
  box-sizing: border-box;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33.33333px;
  width: 13.33333px;
  height: 13.33333px;
  border-radius: 50%;
  background: currentColor;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>
